import * as React from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../layout/DefaultLayout';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';

import { InitiativBewerbungsPage as InitiativBewerbungsPageShared } from '@mangoart/ezagrar/pages/initiativbewerbung';

const InitiativBewerbungsPage = (props) => {
  return (
    <DefaultLayout>
      <InitiativBewerbungsPageShared {...props} />
    </DefaultLayout>
  );
};

export default InitiativBewerbungsPage;

export const InitiativPageQuery = graphql`
  query {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
